<nav id="sidebar" class="sidebar" [ngClass]="{'sidebar-bg' : hasBackgroundImage()}">
  <div class="sidebar-content">
    <div class="sidebar-header">
      <img src="../../../assets/img/logo4.png">
    </div>

    <div class="sidebar-profile">
      <div class="user-pic">
        <img class="img-responsive img-rounded" src="../../../assets/img/" alt="User picture">
      </div>
      <div class="user-info">
          <span class="user-name">İSİM
            <strong>SOYİSİM</strong>
          </span>
        <span class="user-role">ROLE</span>
        <span class="user-status">
            <i class="fa fa-circle"></i>
            <span>Çevirimiçi</span>
          </span>
      </div>
    </div>
    <div class="sidebar-menu">
      <ul>
        <li *ngFor="let menu of menus"
            [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header'}">
          <span *ngIf="menu.type === 'header'">{{menu.title}}</span>
          <a *ngIf="menu.type !== 'header'"   (click)='toggle(menu)'>
            <i class="{{menu.icon}}"></i>
            <span>{{menu.title}}</span>
            <span
              *ngIf="menu.badge"
              class="badge badge-pill"
              [ngClass]="menu.badge.class">
                {{menu.badge.text}}
              </span>
          </a>
          <div *ngIf="menu.type === 'dropdown'"
               class="sidebar-submenu"
               [@slide]="getState(menu)">
            <ul>
              <li *ngFor="let submenu of menu.submenus">
                <a [routerLink]="submenu.routerLink"> {{submenu.title}}
                  <span *ngIf="submenu.badge"
                        class="badge badge-pill"
                        [ngClass]="submenu.badge.class">
                      {{submenu.badge.text}}
                    </span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="sidebar-footer">
    <div class="dropdown" dropdown>

      <a dropdownToggle>
        <i class="fa fa-bell"></i>
        <span class="badge badge-pill badge-warning notification">5</span>
      </a>
      <div class="dropdown-menu notifications" *dropdownMenu>
        <div class="notifications-header">
          <i class="fa fa-bell"></i>
          Notifications
        </div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item"  >
          <div class="notification-content">
            <div class="icon">
              <i class="fas fa-check text-success border border-success"></i>
            </div>
            <div class="content">
              <div class="notification-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                elit. In totam explicabo
              </div>
              <div class="notification-time">
                6 minutes ago
              </div>
            </div>
          </div>
        </a>
        <a class="dropdown-item"  >
          <div class="notification-content">
            <div class="icon">
              <i class="fas fa-exclamation text-info border border-info"></i>
            </div>
            <div class="content">
              <div class="notification-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                elit. In totam explicabo
              </div>
              <div class="notification-time">
                Today
              </div>
            </div>
          </div>
        </a>
        <a class="dropdown-item">
          <div class="notification-content">
            <div class="icon">
              <i class="fas fa-exclamation-triangle text-warning border border-warning"></i>
            </div>
            <div class="content">
              <div class="notification-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                elit. In totam explicabo
              </div>
              <div class="notification-time">
                Yesterday
              </div>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item text-center">View all notifications</a>
      </div>
    </div>
    <div class="dropdown" dropdown>
      <a dropdownToggle>
        <i class="fa fa-envelope"></i>
        <span class="badge badge-pill badge-success notification">7</span>
      </a>
      <div class="dropdown-menu messages" *dropdownMenu>
        <div class="messages-header">
          <i class="fa fa-envelope"></i>
          Messages
        </div>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" >
          <div class="message-content">
            <div class="pic">
              <img src="assets/img/user.jpg" alt="">
            </div>
            <div class="content">
              <div class="message-title">
                <strong> Jhon doe</strong>
              </div>
              <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                elit. In totam explicabo
              </div>
            </div>
          </div>

        </a>
        <a class="dropdown-item">
          <div class="message-content">
            <div class="pic">
              <img src="assets/img/user.jpg" alt="">
            </div>
            <div class="content">
              <div class="message-title">
                <strong> Jhon doe</strong>
              </div>
              <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                elit. In totam explicabo
              </div>
            </div>
          </div>

        </a>
        <a class="dropdown-item" >
          <div class="message-content">
            <div class="pic">
              <img src="assets/img/user.jpg" alt="">
            </div>
            <div class="content">
              <div class="message-title">
                <strong> Jhon doe</strong>
              </div>
              <div class="message-detail">Lorem ipsum dolor sit amet consectetur adipisicing
                elit. In totam explicabo
              </div>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item text-center"  >View all messages</a>

      </div>
    </div>
    <div class="dropdown" dropdown>
      <a   dropdownToggle>
        <i class="fa fa-cog"></i>
        <span class="badge-sonar"></span>
      </a>
      <div class="dropdown-menu" *dropdownMenu>
        <a class="dropdown-item"  >My profile</a>
        <a class="dropdown-item"  >Help</a>
        <a class="dropdown-item"  >Setting</a>
      </div>
    </div>
    <div>
      <a>
        <i class="fa fa-power-off"></i>
      </a>
    </div>
  </div>
</nav>
