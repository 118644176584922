<div class="about-container">
  <div class="info">
    <div class="container">
      <div class="text col-sm-12 col-md-12 col-lg-12">
        <h1 class="title">HAKKIMIZDA</h1>
        <p class="paragraph" style="color:white;">
          Firmamız, sarrafiye altın çeşitleri alım-satımı, has altın alım-satımı ile ticaret
          hayatına başlamıştır. Kısa sürede müşteri portföyünü genişletip, müşterisine verdiği hizmet kalitesi adından söz
          ettirmiş, emin adımlarla iş hacmini genişletmiştir. <br>
          Araz Gold,sektörde lider konuma sahip ve genç beyinlerin önderliğinde büyümeye önem vermiş güvenilir
          şirketler lisesinde önde gelen bir firmadır.<br>
        </p>
      </div>
    </div>
  </div>
</div>
