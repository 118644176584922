<div class="request-form">
  <div class="container-fluid" style="display:flex;flex-wrap: wrap; height: 100%;width: 100vw;align-items: center;">
    <div class="row">
      <div class="left-div col-sm-12 col-md-12 col-lg-8">
        <app-carousel [slides]="slides" [animationType]="animationType"></app-carousel>
      </div>
      <div class="right-div col-sm-12 col-md-12 col-lg-4">
        <div class="overflow-box">
          <div class="grid-box" *ngFor="let data of dataList4 ; let i=index; trackBy : trackByPrice">
            <div class="grid-card"
                 [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{data.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
                <!---BID START-->
                <span class="price">
            {{data.Bid.toFixed(3) | number:'1.2-2'}}
              </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price">
         {{data.Ask.toFixed(3) | number:'1.2-2'}}
              </span>
                <!--ASK END-->
                <span class="price-icon" *ngIf="data.askPercentChange>0"
                      style="color: #3a9d37;">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="price-icon" *ngIf="data.askPercentChange<0"
                      style="color: #f25961;">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="price-icon" *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                      style="color: orange;">
                <i class="fa fa-minus"></i>
              </span>
                <span *ngIf="data.askPercentChange>0" class="grid-all"
                      style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange<0" class="grid-all"
                      style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                      class="grid-all"
                      style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>
        <div class="app-button">
          <div class="col-6" style="border-right: 1px solid white">
            <a type="button" href="https://apps.apple.com/tr/app/araz-gold/id1546717685?l=tr" target="_blank">
              <img src="../../assets/img/appstore.png">
            </a>
          </div>
          <div class="col-6">
            <a type="button" href="https://play.google.com/store/apps/details?id=com.aifasoft.araz_kiymetli&hl=tr"
               target="_blank">
              <img src="../../assets/img/googleplay.png">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>


<div class="container">
  <div class="row">
    <!-- feature item -->
    <div class="col-lg-4 col-md-12 align-self-center text-center">
      <div class=" phone-img"></div>
    </div>
    <!-- feature item -->
    <div class="text-content col-lg-8 col-md-12 align-self-center text-center ">
      <div class=" subcontent ">
        <div class="subcontent-icon icon1 col-4"></div>
        <div class="subcontent-text col-8"> Anlık fiyat takibi ile riskinizi en aza indirin.</div>
      </div>
      <div class="subcontent">
        <div class="subcontent-icon icon2 col-4"></div>
        <div class="subcontent-text col-8">Telefon görüşmeleri ile zaman kaybetmeyin ve fiyat kaçırmayın.</div>
      </div>
      <div class="subcontent">
        <div class="subcontent-icon icon3 col-4"></div>
        <div class="subcontent-text col-8"> Mobil uygulamamız ile her zaman yanınızdayız.</div>
      </div>
      <div class="subcontent">
        <div class="subcontent-icon icon4 col-4"></div>
        <div class="subcontent-text col-8"> Fiyatlarımıza en güvenli şekilde ulaşın.

        </div>
      </div>
    </div>
  </div>
</div>
<div class="content">
  <div class="who">
    <div class="wyre-container col-lg-4 col-md-2  d-none d-lg-block">
      <img height="100px" src="../../assets/img/logo3.png">
    </div>
    <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
      <div class="who-content">
        <h1>Adres</h1>
        {{ parameters.address }}
      </div>
    </div>
    <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
      <div class="who-content">
        <h1>Telefon</h1>
        <a href="tel:{{ parameters.phone }}">{{ parameters.phone }}</a>
      </div>
    </div>
  </div>
</div>
