<div class="home-container col-12">
  <div style="display: flex;flex-wrap: wrap;height: 100vh;overflow-y: auto;" class="col-lg-12 col-md-12 col-sm-12" > 
    <div class="piece-div col-sm-12 col-md-12 col-lg-6" >
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-9">
            <div class="categoryName col-sm-6 col-md-6 col-lg-10">
              SARRAFİYE
            </div>
            <div class="bid " style="position:relative; right:3em" id="bidPrice" >
              ALIŞ
            </div>
            <div class="ask col-lg-12" id="askPrice">
              SATIŞ
            </div>
     
          </div>
          <div class="click-icon col-2" style="margin-left:3em" (click)="resizeDiv(sarrafiyeList[0].Category)">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="sarrafiyeContainer" style="height:50em;">
          <div class="grid-box" *ngFor="let socketData of sarrafiyeList; let i=index; trackBy : trackByPrice" >
            <div class="grid-card" (click)="toggle(socketData)" [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
              <span class="grid-text col-sm-3 col-md-3 col-lg-3">
                {{socketData.Code}}
              </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="socketData.askPercentChange>0"
                  style="color: #3a9d37;padding: 0;text-align: center">
                  <i class="fa fa-angle-up"></i>
                </span>
                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="socketData.askPercentChange<0"
                  style="color: #f25961;padding: 0;text-align: center">
                  <i class="fa fa-angle-down"></i>
                </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                  *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                  style="color: orange;padding: 0;text-align: center">
                  <i class="fa fa-minus"></i>
                </span>
                <!---BID START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
                  {{socketData.Bid | number:'1.2-4'}}
                </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
                  {{socketData.Ask | number:'1.2-4'}}
                </span>
                <!--ASK END-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--SARRAFİYE END --->
    <!--GRAMALTIN START --->
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-9">
            <div class="categoryName col-sm-6 col-md-6 col-lg-10">
              GRAM ALTIN
            </div>
            <div class="bid " style="position:relative; right:3em" id="bidPrice" >
              ALIŞ
            </div>
            <div class="ask col-lg-12" id="askPrice">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2"  style="margin-left:3em" (click)="resizeDiv(gramGoldList[0].Category)">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="gramContainer" style="height:50em;">
          <div class="grid-box" *ngFor="let socketData of gramGoldList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)" [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
              <span class="grid-text col-sm-3 col-md-3 col-lg-3">
                {{socketData.Code}}
              </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="socketData.askPercentChange>0"
                  style="color: #3a9d37;padding: 0;text-align: center">
                  <i class="fa fa-angle-up"></i>
                </span>
                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="socketData.askPercentChange<0"
                  style="color: #f25961;padding: 0;text-align: center">
                  <i class="fa fa-angle-down"></i>
                </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                  *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                  style="color: orange;padding: 0;text-align: center">
                  <i class="fa fa-minus"></i>
                </span>
                <!---BID START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
                  {{socketData.Bid | number:'1.2-4'}}
                </span>
                <!---BID END-->
                <!--ASK START-->
                <span class="price col-sm-3 col-md-3 col-lg-3">
                  {{socketData.Ask | number:'1.2-4'}}
                </span>
                <!--ASK END-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              PARITE
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv(parityList[0].Category)">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="parityContainer">
          <div class="grid-box" *ngFor="let socketData of parityList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)"
                 [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{socketData.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="socketData.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{socketData.Bid | number:'1.3-4'}}
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{socketData.Ask | number:'1.3-4'}}
              </span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              KRIPTO
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv(otherList[0].Category)">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="otherContainer">
          <div class="grid-box" *ngFor="let socketData of otherList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)"
                 [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{socketData.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="socketData.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{socketData.Bid | number:'1.2-2'}}
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{socketData.Ask | number:'1.2-2'}}
              </span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              DOVIZ
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv(currencyList[0].Category)">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="currencyContainer">
          <div class="grid-box" *ngFor="let data of currencyList ; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(data)"
                 [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{data.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="data.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="data.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{data.Bid | number:'1.2-2'}}
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{data.Ask | number:'1.2-2'}}
              </span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="piece-div col-sm-12 col-md-12 col-lg-6">
      <div class="grid-container">
        <div class="title">
          <div class="title-fixed col-10">
            <div class="categoryName col-sm-6 col-md-6 col-lg-6">
              GENEL
            </div>
            <div class="bid col-sm-3 col-md-3 col-lg-3">
              ALIŞ
            </div>
            <div class="ask col-sm-3 col-md-3 col-lg-3">
              SATIŞ
            </div>
          </div>
          <div class="click-icon col-2" (click)="resizeDiv(goldList[0].Category)">
            <i class="fa fa-expand-arrows-alt"></i>
          </div>
        </div>

        <div class="overflow-box" id="goldContainer">
          <div class="grid-box" *ngFor="let socketData of goldList; let i=index; trackBy : trackByPrice">
            <div class="grid-card" (click)="toggle(socketData)"
                 [ngClass]="{'grid-card-up': socketData.askPercentChange >0,
                 'grid-card-down': socketData.askPercentChange <0}">
            <span class="grid-text col-sm-3 col-md-3 col-lg-3">
              {{socketData.Code}}
            </span>
              <div class="price-tag col-sm-9 col-md-9 col-lg-9">
              <span class="col-sm-3 col-md-3 col-lg-3"
                    *ngIf="socketData.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i class="fa fa-angle-up"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange<0"
                      style="color: #f25961;padding: 0;text-align: center">
                <i class="fa fa-angle-down"></i>
              </span>
                <span class="col-sm-3 col-md-3 col-lg-3"
                      *ngIf="socketData.askPercentChange===0 || !socketData.askPercentChange"
                      style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
            {{socketData.Bid | number:'1.2-2'}}
       </span> 
                <span class="price col-sm-3 col-md-3 col-lg-3">
         {{socketData.Ask | number:'1.2-2'}}
              </span> 
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div> 
  </div>

    <div style="height: 100vh;overflow-y: auto;" class="col-lg-4 col-md-12 col-sm-12">
      <div class="piece-div col-sm-12 col-md-12 col-lg-12">
        <div class="title-rss">
          HABERLER
        </div>
        <div class="rss-container">
          <app-rss></app-rss>
        </div>
      </div>
      <div class="piece-div col-sm-12 col-md-12 col-lg-12">
        <div class="chart">
          <app-chart [code]="code"></app-chart>
        </div>
      </div>
    </div>-->
  </div>