import {AfterContentInit, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SocketData} from '../_models/socketData';
import {HomeProvider} from '../_services/home.provider';
import {Subscription} from 'rxjs';
import {Categories} from '../_models/categories';
import AOS from 'aos';
import {CarouselComponent} from '../_components/carousel/carousel.component';
import {AnimationType} from '../_components/carousel/carousel.animation';
import {Slide} from '../_components/carousel/carousel.interface';
import {CATEGORYTYPE} from '../_models/categoryType';
import { params } from '../shared/configuration';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(CarouselComponent) carousel: CarouselComponent;

  parameters:any=params;

  animationType = AnimationType.Fade;

  animationTypes = [
    {
      name: 'Fade',
      value: AnimationType.Fade
    }
  ];
  slides: Slide[] = [
    {
      headline: '',
      src:
        '../../assets/img/banner1.png'
    },
    {
      headline: '',
      src:
        '../../assets/img/banner2.png'
    },
    {
      headline: '',
      src:
        '../../assets/img/banner3.png'
    }
  ];
  categories: Categories[] = [];
  code: string;

  socketDataList: SocketData[] = [];
  dataList1: SocketData[] = [];
  dataList2: SocketData[] = [];
  dataList3: SocketData[] = [];
  dataList4: SocketData[] = [];
  dataList5: SocketData[] = [];
  dataList6: SocketData[] = [];
  dataListReplace1: SocketData[] = [];
  dataListReplace2: SocketData[] = [];
  dataListReplace3: SocketData[] = [];
  dataListReplace4: SocketData[] = [];
  dataListReplace5: SocketData[] = [];
  dataListReplace6: SocketData[] = [];

  private subscriptions = new Subscription();
  public pingStatus = true;
  RETRY_SECONDS = 30;
  timer: any;
  interval: any;

  constructor(private service: HomeProvider,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.pingStatus === false) {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.getSocketData();
      }
    }, this.RETRY_SECONDS * 1000);
    this.getSocketData();
    this.categories = this.service.getCategories();
    AOS.init();
  }

  getSocketData() {
    this.service.initSocket();

    this.subscriptions.add(this.service.connectWebSocket().subscribe((Sdata: SocketData[]) => {
        clearTimeout(this.timer);
        this.pingStatus = true;
        this.socketDataList = Sdata;
        this.filterCategories();
        this.timer = setTimeout(() => {
          this.pingStatus = false;
        }, 2000);
      },
      (err) => {
        this.pingStatus = true;
      },
      () => {
        this.pingStatus = false;
      }));
  }

  trackByPrice(index: number, code) {
    return code.Ask;
  }

  filterCategories() {
    this.dataList4 = [];
    this.dataList6 = [];
    this.socketDataList.forEach((item, index) => {
      if (item.Category === CATEGORYTYPE.SARRAFIYE) {
        this.dataList4.push(item);
      } else {
        this.dataList6.push(item);
      }
    });
    if (this.dataListReplace4.length !== 0) {
      if (JSON.stringify(this.dataListReplace4) === JSON.stringify(this.dataList4)) {

      } else {
        this.dataList4.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace4[index].Ask) {
            this.percentChange(data, this.dataListReplace4[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace4[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace4[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace4 = this.dataList4;
    }
    if (this.dataListReplace6.length !== 0) {
      if (JSON.stringify(this.dataListReplace6) === JSON.stringify(this.dataList6)) {

      } else {
        this.dataList6.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace6[index].Ask) {
            this.percentChange(data, this.dataListReplace6[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace6[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace6[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace6 = this.dataList6;
    }

  }

  percentChange(newData, oldData, index) {
    if (newData.Ask !== oldData.Ask) {
      let oldAskPrice = +oldData.Ask;
      let newAskPrice = +newData.Ask;
      let askPriceDifference = (1 - (oldAskPrice / newAskPrice)) * 100;
      newData.askPercentChange = +askPriceDifference.toFixed(2);
      newData.Time = Date.now();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
