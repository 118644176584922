<header class="fancy">
  <div class="iq-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="logo col-4">
            <a><img id="logo_dark" class="img-fluid" src="../../../assets/img/logo3.png" alt="logo"></a>
          </div>
          <nav class="col-8"><a id="resp-menu" class="responsive-menu" href="javascript:void(0)"><i class="fas fa-bars"></i></a>
            <ul class="menu text-right">
              <li  (click)="route('home')"><a href="javascript:void(0)">ANASAYFA</a>
              </li>
              <li (click)="route('currency')"><a href="javascript:void(0)">Canlı Piyasa</a>
              </li>
              <li (click)="route('info')"><a href="javascript:void(0)">Hakkımızda</a>
              </li>
              <li (click)="route('comm')"><a href="javascript:void(0)">İletişim</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>
<nav class="navbar navbar-expand-lg col-12">
  <a class="navbar-brand col-sm-5 col-md-2 col-lg-3" (click)="route('home')">
    <img style="width: 150px;" src="../../../assets/img/logo3.png">
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-bars"></i>
  </button>
  <div class="collapse navbar-collapse col-sm-12 col-md-12 col-lg-9 show" id="navbarSupportedContent"
       [ngClass]="{ 'show': navbarOpen }">
    <div class="navbar-nav col-12 col-sm-12 col-md-12 col-lg-12">
      <div id="home" class="nav-item">
        <div class="text" (click)="route('home')">
          <a class="nav-text"> ANASAYFA </a>
        </div>
      </div>
      <div id="currency" class="nav-item ">
        <div class="text" (click)="route('currency')">
          <a class="nav-text"> CANLI PİYASA </a>
        </div>
      </div>
      <div id="info" class="nav-item">
        <div class="text" (click)="route('info')">
          <a class="nav-text"> HAKKIMIZDA </a>
        </div>
      </div>
      <div id="comm" class="nav-item">
        <div class="text" (click)="route('comm')">
          <a class="nav-text"> İLETİŞİM </a>
        </div>
      </div>
    </div>
  </div>
</nav>
