import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SocketData} from '../_models/socketData';
import {HomeProvider} from '../_services/home.provider';
import {CATEGORYTYPE} from '../_models/categoryType';
import {Subscription} from 'rxjs';
import {ROUTING} from '../shared/routing';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {
  routeState: any;
  socketDataList: SocketData[] = [];
  dataList: SocketData[] = [];
  dataListReplace1: SocketData[] = [];

  code: string = 'USDTRY';
  private subscriptions = new Subscription();
  public pingStatus = true;
  RETRY_SECONDS = 30;
  timer: any;
  interval: any;

  constructor(private router: Router,
              private wsService: HomeProvider) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.routeState = this.router.getCurrentNavigation().extras.state.category;
    } else {
      this.router.navigateByUrl(ROUTING.CURRENCY);
    }
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.pingStatus === false) {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.getData();
      }
    }, this.RETRY_SECONDS * 1000);
    this.getData();
  }

  getData() {
    this.wsService.initSocket();

    this.subscriptions.add(this.wsService.connectWebSocket().subscribe((Sdata: SocketData[]) => {
        clearTimeout(this.timer);
        this.pingStatus = true;
        this.socketDataList = Sdata;
        this.filterData();
        this.timer = setTimeout(() => {
          this.pingStatus = false;
        }, 2000);
      },
      (err) => {
        this.pingStatus = true;
      },
      () => {
        this.pingStatus = false;
      }));
  }

  filterData() {
    if (this.dataList) {
      this.dataList = [];
    }
    this.socketDataList.forEach((item) => {
      if (item.Category === this.routeState) {
        this.dataList.push(item);
      } else {
      }
    });
    if (this.dataListReplace1.length !== 0) {
      if (JSON.stringify(this.dataListReplace1) === JSON.stringify(this.dataList)) {

      } else {
        this.dataList.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace1[index].Ask) {
            this.percentChange(data, this.dataListReplace1[index]);
          } else {
            data.askPercentChange = 0.00;
            this.dataListReplace1[index].askPercentChange = data.askPercentChange;
          }
        });
      }
    } else {
      this.dataListReplace1 = this.dataList;
    }
  }

  percentChange(newData, oldData) {
    if (newData.Ask !== oldData.Ask) {
      const oldAskPrice = +oldData.Ask;
      const newAskPrice = +newData.Ask;
      const askPriceDifference = (1 - (oldAskPrice / newAskPrice)) * 100;
      newData.askPercentChange = +askPriceDifference.toFixed(2);
      newData.Time = Date.now();
      if (askPriceDifference < 0) {
        const code = newData.Code;
        const element = document.getElementById(code);
      } else if (askPriceDifference > 0) {
        const code = newData.Code;
        const element = document.getElementById(code);
      }
    }
  }

  trackByPrice(index: number, code) {
    return code.Ask;
  }
}
