export const servers = {
  //real: 'wss://srv3.edsserver.net:18001'
  real: 'wss://sslsocket.aifanet.com/152.89.36.147/18001'
};
export const params = {
  phone: '(0212) 520 53 53',
  address: 'Beyazıt Mh. Güllaç Sk. No:8 34126 Fatih/İstanbul',
  googlemap: ''
}

