import {Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTING} from '../../shared/routing';
import {MatDialog} from '@angular/material/dialog';
import {SidebarService} from '../../_services/siderbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  time = new Date();
  navbarOpen = false;
  isCollapsed = true;

  constructor(private router: Router,
              private renderer: Renderer2,
              public dialog: MatDialog,
              public sidebarservice: SidebarService) {
  }

  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  route(page) {
    switch (page) {
      case 'home': {
        this.router.navigateByUrl(ROUTING.HOME);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'currency': {
        this.router.navigateByUrl(ROUTING.CURRENCY);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'gold': {
        this.router.navigateByUrl(ROUTING.GOLD);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'info': {
        this.router.navigateByUrl(ROUTING.INFORMATION);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'comm': {
        this.router.navigateByUrl(ROUTING.CONTACT);
        localStorage.setItem('selectedPage', page);

        break;
      }
    }

  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementsByClassName('fancy')[0].className = 'fancy menu-sticky';
    } else {
      document.getElementsByClassName('fancy')[0].className = 'fancy';
    }
  }
}
