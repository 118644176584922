<div class="request-form">
  <div *ngIf="routeState" class="container">
    <div class="row">
      <div class="right-div col-sm-12 col-md-12 col-lg-12">
        <div class="tab-button">
          <div class="col-4">{{ routeState }}</div>
          <div class="col-4 bid">ALIŞ</div>
          <div class="col-4 ask">SATIŞ</div>
        </div>
        <div class="overflow-box">
          <div
            class="grid-box" style="
            height: 5em;
            "
            *ngFor="let data of dataList; let i = index; trackBy: trackByPrice"
          >
            <div
              class="grid-card"
              [ngClass]="{
                'grid-card-up': data.askPercentChange > 0,
                'grid-card-down': data.askPercentChange < 0
              }"
            >
              <div class="grid-text col-4 col">
                {{ data.Code }} 
                <div class="grid-date">{{ data.Time | date: "h:mm:ss" }}</div>
              </div>



              <div *ngIf="data.Category === 'PARITE'" class="price col-4">
                {{ data.Bid | number: "1.4-4" }} <br>
        
              </div>





              
              <div *ngIf="data.Category !== 'PARITE'" class="price col-4">
                {{ data.Bid | number: "1.2-2" }} <br>
              </div>
              <span
                class="rate-icon"
                *ngIf="data.askPercentChange > 0"
                style="color: #3a9d37; padding: 0; text-align: center;"
              >
                <i style="font-size: 20px" class="fas fa-caret-up"></i>
              </span>
              <span
                class="rate-icon"
                *ngIf="data.askPercentChange < 0"
                style="color: #f25961; padding: 0; text-align: center;"
              >
                <i style="font-size: 20px" class="fas fa-caret-down"></i>
              </span>
              <span
                class="rate-icon"
                *ngIf="data.askPercentChange === 0 || !data.askPercentChange"
                style="color: orange; padding: 0; text-align: center;"
              >
                <i class="fa fa-minus"></i>
              </span>





              

              <div *ngIf="data.Category === 'PARITE'" class="price col-4">
                {{ data.Ask | number: "1.4-4" }} <br>
                <span
                  *ngIf="data.askPercentChange > 0"
                  class="grid-all col-sm-3 col-md-3 col-lg-3"
                  style="color: #3a9d37;position:absolute;top:30px; right:5px;"
                >
                  %{{ data.askPercentChange }}
                </span>
                <span
                  *ngIf="data.askPercentChange < 0"
                  class="grid-all col-sm-3 col-md-3 col-lg-3"
                  style="color: #f25961;position: absolute;top:30px; right:5px;"
                >
                  %{{ data.askPercentChange }}
                </span>
                <span
                  *ngIf="data.askPercentChange == 0 || !data.askPercentChange"
                  class="grid-all col-sm-3 col-md-3 col-lg-3"
                  style="color: orange;position: absolute;top:30px; right:5px;"
                >
                  %{{ data.askPercentChange }}0.00
                  
                </span>
              </div>
              <div *ngIf="data.Category !== 'PARITE'" class="price col-4">
                {{ data.Ask | number: "1.2-3" }} 
                  <span
                    *ngIf="data.askPercentChange > 0"
                    class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #3a9d37;position: absolute; right:10px; top: 30px;"
                  >
                    %{{ data.askPercentChange }}
                  </span>
                  <span
                    *ngIf="data.askPercentChange < 0"
                    class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #f25961;position: absolute; right:10px; top: 30px;"
                  >
                    %{{ data.askPercentChange }}
                  </span>
                  <span
                    *ngIf="data.askPercentChange == 0 || !data.askPercentChange"
                    class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: orange;position: absolute; right:10px; top: 30px;"
                  >
                    %{{ data.askPercentChange }}0.00
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
