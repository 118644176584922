
      <div class="contact-container col-md-10 offset-md-1">
        <div class="maps-form col-sm-12 col-md-12 col-lg-12">
          <div class="tel-social">
            <div class="tel col-sm-12 col-md-12 col-lg-12">
              <h5 class="title">Telefon</h5>
              <p class="subtitle">{{ parameters.phone }}</p>
            </div>
          </div>
          <div class="address">
            <h5 class="title">Adres</h5>
            <p class="map-text">
              {{ parameters.address }}
            </p>
          </div>
        </div>
        <div class="contact-form col-sm-12 col-md-12 col-lg-12">
          <h1 class="title">BİZE ULAŞIN</h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12042.630570444886!2d28.969676!3d41.010866!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd39c2478cc2fa197!2zQVJBWiBLSVlNRVRMxLAgTUFERU5MRVIgVMSwQyBBLsWe!5e0!3m2!1str!2str!4v1611567283476!5m2!1str!2str"
            width="600"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>

